import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "paper-summary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#paper-summary",
        "aria-label": "paper summary permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Paper Summary`}</h1>
    <h2 {...{
      "id": "rubric",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#rubric",
        "aria-label": "rubric permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Rubric`}</h2>
    <p>{`To receive a grade, you must `}<a parentName="p" {...{
        "href": "https://github.com/uazhlt-ms-program/ling-582-course-blog/pulls",
        "target": "_self",
        "rel": "nofollow"
      }}>{`create a pull request in the course blog repository`}</a>{` with a final (updated) version of your paper summary. The final version of your paper summary will be assessed based on how well your summary answers the questions listed below.  Each question will be graded on either a Superior/Pass/Fail or Pass/Fail basis.  Assignment letter grades are calculated in the following manner:`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
      <tbody>
        <tr>
            <td><strong>Grade</strong></td>
            <td><strong>Criteria</strong></td>
        </tr>
        <tr>
            <td>
              <p>A</p>
            </td>
            <td>
              <p>No <strong>Fail</strong> and at least 2 <strong>Superior</strong></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>B</p>
            </td>
            <td>
              <p>All <strong>Pass</strong></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>C</p>
            </td>
            <td>
              <p>1 <strong>Failure</strong></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>D</p>
            </td>
            <td>
              <p>2 <strong>Failure</strong></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>F</p>
            </td>
            <td>
              <p>3+ <strong>Failure</strong></p>
            </td>
        </tr>
    </tbody>
    </HTMLTable>
    <h3 {...{
      "id": "questions-to-be-answered",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#questions-to-be-answered",
        "aria-label": "questions to be answered permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Questions to be answered:`}</h3>
    <ul>
      <li parentName="ul">{`What are the authors proposing?`}</li>
      <li parentName="ul">{`What is the motivation for the work?`}</li>
      <li parentName="ul">{`What are the results and how do they compare with competing approaches?`}</li>
      <li parentName="ul">{`Are the comparisons fair?`}</li>
      <li parentName="ul">{`What are the takeaways according to the authors? `}</li>
      <li parentName="ul">{`What are the takeaways according to you?`}</li>
      <li parentName="ul">{`Would you use this? If so, how/where would you use this?`}</li>
      <li parentName="ul">{`What problems remain and what are the next steps?`}</li>
    </ul>
    <p><strong parentName="p">{`Superior`}</strong>{` Response answers the question completely while remaining succinct.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Pass`}</strong>{`: A response is provided, but may be unclear, incomplete, or overly detailed (as opposed to a summary).`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Fail`}</strong>{`: No response is provided or the response fails to answer the question.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      